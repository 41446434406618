import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);          

TagManager.dataLayer({
  dataLayer: {
    'event': 'windowLoaded',
  },
});
console.log('TagManager initialized');

localStorage.setItem("tsol_voucher_gtm_prevent_event", 'true');

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
