import { createAsyncThunk } from "@reduxjs/toolkit";
import { createActions } from "redux-actions";
import BaseApi from "services/BaseApi.service";

let voucherFlowActions = createActions({
  SET_VOUCHER_CODE: (code) => code,
});

voucherFlowActions.testVoucherCode = createAsyncThunk(
  "TEST_VOUCHER_CODE",
  async (voucherId) => {
    return await BaseApi.testVoucherCode(voucherId);
  }
);

export const { setVoucherCode, testVoucherCode } = voucherFlowActions;


export default voucherFlowActions;
