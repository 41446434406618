import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  colours: {
    white: "#FFFFFF",
    black: "#000000",
    vividYellow: "#ffe802",
    nearlyBlack: "#1c1c1c",
    veryDarkGray: "#323232",
    darkShadeOfGray: "#373737",
    veryLightGray: "#dddddd",
    lightGray: "#c8c8c8",
    red: "#ff0000",
    tundora: "#4c4c4c",
    header: {
      background: "#fcd122",
      text: "#FFFFFF",
    },
    footer: {
      background: "#1c1c1c",
      text: "#FFFFFF",
    },
    buttons: {
      progression: {
        primary: {
          background: "#ffe802",
          color: "#000000",
        },
        secondary: {
          background: "#323232",
          color: "#FFFFFF",
        },
      },
    },
  },
});
