import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import UnderlineButton from "components/atoms/UnderlineButton.atom";
import { makeStyles } from "@material-ui/core/styles";

const usePleaseLoginStyles = makeStyles((theme) => ({
  root: {
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fonStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    color: theme.colours.black,
    fontFamily: "Scala",
  },
  link: {
    display: "inline-flex !important",
    textAlign: "left",
    width: "auto",
    justifyContent: "flex-start",
    minWidth: "auto",
    fontSize: "inherit",
    "& span:nth-child(2)": {
      display: "none",
    },
  },
}));

const PleaseLogin = () => {
  const classes = usePleaseLoginStyles();
  return (
    <Typography className={classes.root}>
      Please{" "}
      <Link to="/create-account">
        <UnderlineButton className={classes.link}>sign up</UnderlineButton>
      </Link>{" "}
      or
      <Link to="/login">
        {" "}
        <UnderlineButton className={classes.link}>sign in</UnderlineButton>
      </Link>{" "}
      first
    </Typography>
  );
};

export default PleaseLogin;
