import React from "react";
import Heading from "components/molecules/Header.molecule";
import Footer from "components/molecules/Footer.molecule";
import { makeStyles } from "@material-ui/core/styles";
import HighlightedHeader from "components/atoms/HighlightedHeader.atom";
import HeaderBanner from "components/molecules/HeaderBanner.molecule";
import Typography from "@material-ui/core/Typography";
import ProgressionButton from "components/atoms/ProgressionButton.atom";
import { Link } from "react-router-dom";
import voucherCodeLinks from "routes/voucherCode.routes";
import PageWidth from "components/primitives/PageWidth.primitive";

const usePageNotFoundStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Scala",
    margin: "0 0 110px",
  },
  content: {
    fontFamily: "Scala",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    color: theme.colours.black,
    margin: "15px 0 25px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      lineHeight: 1.56,
    },
  },
}));
const PageNotFound = () => {
  const classes = usePageNotFoundStyles();

  return (
    <main>
      <Heading />
      <div className={classes.container}>
        <HeaderBanner />
        <PageWidth>
          <HighlightedHeader>
            Sorry, it seems you were trying to access a page that doesn’t exist.
          </HighlightedHeader>
          <Typography variant="body1" className={classes.content}>
            Either check the spelling of the URL you were trying to access or go
            back to the homepage.
          </Typography>
          <Link to={voucherCodeLinks.landingPage.path}>
            <ProgressionButton>go to homepage</ProgressionButton>
          </Link>
        </PageWidth>
      </div>
      <Footer />
    </main>
  );
};

export default PageNotFound;
