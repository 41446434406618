import { createSlice } from '@reduxjs/toolkit'

export const summarySlice = createSlice({
  name: 'summary',
  initialState: {
    street: '',
    city: '',
    postalCode: '',
    country: '',
  },
  reducers: {
    saveStreet: (state, action) => {
      state.street = action.payload.street;
    },
    saveCity: (state, action) => {
      state.city = action.payload.city;
    },
    savePostalCode: (state, action) => {
      state.postalCode = action.payload.postalCode;
    },
    saveCountry: (state, action) => {
      state.country = action.payload.country;
    },
  },
})


export const { saveStreet, saveCity, savePostalCode, saveCountry } = summarySlice.actions;

export default summarySlice.reducer;
