import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import MasterThemeProvider from "components/providers/MasterTheme.provider";
import NetworkProvider from "components/providers/Network.provider";
import Routes from "routes/ControllerComponent.routes";
import defaultTheme from "themes/defaultTheme";
import rootStore from "redux/root.store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useToken } from "hooks/useToken.hook";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";

let persistor = persistStore(rootStore);
function App() {
  /* Handle the theme object [theme, setTheme] */
  const [theme] = React.useState(defaultTheme);

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  //hook to update access & refresh token based on existing token
  useToken();

  return (
    <Provider store={rootStore}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            {/* Handles routing ("react-router-dom") */}
            <MasterThemeProvider theme={theme}>
              {/* Handle both StyledComponents and Material theme objects */}
              <NetworkProvider>
                {/* Handle any network interruptions */}
                <Routes /> {/* Display the current route */}
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </NetworkProvider>
            </MasterThemeProvider>
          </Router>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
