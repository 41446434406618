import React from "react";
import ImagePath, { images } from "classes/ImagePath.class";
import Button from "components/primitives/Button.primitive";
import SVG from "components/primitives/SVGImage.primitive";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useProgressionButtonStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.colours.buttons.progression.secondary.background} !important`,
    color: `${theme.colours.buttons.progression.secondary.color} !important`,
    textTransform: "uppercase",
    padding: "14px 15px 11px 21px !important",
    width: "auto",
    justifyContent: "space-between",
    minWidth: 180,
    fontFamily: "BebasNeue",
    fontSize: 18,
    letterSpacing: 0.56,
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      letterSpacing: 0.56,
      padding: "7px 7px 5.5px 10.5px !important",
    },
    "& p": {
      margin: 0,
    },
    "& pre": {
      margin: 0,
    },
  },
  primary: {
    backgroundColor: `${theme.colours.buttons.progression.primary.background} !important`,
    color: `${theme.colours.buttons.progression.primary.color} !important`,
  },
  full: {
    width: "100%",
  },
  wrap: {
    "& pre": {
      whiteSpace: "normal",
    },
  },
  alignLeft: {
    textAlign: "left",
    "& pre": {
      textAlign: "left",
    },
  },
  arrow: {
    width: 30,
    height: 10,
    background: theme.colours.buttons.progression.secondary.color,
  },
  arrowPrimary: {
    background: theme.colours.buttons.progression.primary.color,
  },
}));

export default function ProgressionButton({
  children,
  className,
  showIcon = true,
  ...props
}) {
  const classes = useProgressionButtonStyle();
  return (
    <Button
      className={clsx(
        classes.root,
        {
          [classes.primary]: props.variant === "contained",
          [classes.full]: props.fullWidth,
          [classes.wrap]: props.wrap,
          [classes.alignLeft]: props.alignLeft,
        },
        className
      )}
      {...props}
    >
      {children}
      {showIcon && (
        <SVG
          primary={props.primary}
          path={ImagePath.returnImage(images.icons.rightArrow)}
          className={clsx(classes.arrow, {
            [classes.arrowPrimary]: props.variant === "contained",
          })}
        />
      )}
    </Button>
  );
}
