import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import authenticationReducer from "redux/authentication/authentication.reducer";
import voucherCodeReducer from "redux/voucher/voucherCode.reducer";
import prismicReducer from "redux/prismic/prismic.reducer";
import currencyReducer from "redux/currency/currency.reducer";
import summarySlice from "redux/summary/summarySlice";
import thunk from "redux-thunk";

const reducers = combineReducers({
  authentication: authenticationReducer,
  voucherCode: voucherCodeReducer,
  prismic: prismicReducer,
  currency: currencyReducer,
  summary: summarySlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
