import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImagePath, { images } from "classes/ImagePath.class";

const useHeaderBannerStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 300,
    marginBottom: 80,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundImage: (props) => `url(${props.desktopImageUrl})`,
    [theme.breakpoints.down("xs")]: {
      height: 321,
      marginBottom: 60,
      backgroundImage: (props) => `url(${props.mobileImageUrl})`,
    },
  },
}));

export default function HeaderBanner({
  desktopImageUrl = ImagePath.returnImage(images.headerBanner.backupDesktop),
  mobileImageUrl = ImagePath.returnImage(images.headerBanner.backupMobile),
}) {
  const classes = useHeaderBannerStyles({ desktopImageUrl, mobileImageUrl });

  return <div className={classes.root}></div>;
}
