import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const usePageWidthStyle = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 18.5px",
    },
  },
  twoColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    maxWidth: 1200,
    [theme.breakpoints.down("sm")]: {
      display: "block", // Stack columns on small screens
    },
  },
  column: {
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  singleColumn: {
    width: 380,
    minWidth: 170,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

const PageWidth = ({ children, isTwoColumn = false }) => {
  const classes = usePageWidthStyle();
  const childrenArray = React.Children.toArray(children);
  const useTwoColumns = isTwoColumn && childrenArray.length > 1;

  return (
    <div
      className={`${classes.root} ${
        useTwoColumns ? classes.twoColumn : classes.singleColumn
      }`}
    >
      {useTwoColumns ? (
        <>
          <div className={classes.column}>{childrenArray[0]}</div>
          <div className={classes.column}>
            {childrenArray.length > 1 ? childrenArray[1] : null}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default PageWidth;
