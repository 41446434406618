import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import voucherCodeRoutes from "./voucherCode.routes";
import PageNotFound from "components/organisms/PageNotFound.organism";
import LoadingPage from "components/organisms/LoadingPage.organism";
import BlockAccess from "components/organisms/BlockAccess.organism";
import TagManager from 'react-gtm-module';
import debounce from "utils/debounce";

const paths = {
  ...voucherCodeRoutes,
};

// Prevent rapid page view events to reduce analytics noise.


export default function Routes() {
  const history = useHistory();

  React.useEffect(() => {
    const sendPageViewEvent = () => {
      if ('true' !== localStorage.getItem("tsol_voucher_gtm_prevent_event")) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'newPageLoaded',
            page: history.location.pathname,
          }
        });
      }
      // Reset the flag after sending the pageview event or if it's not set
      localStorage.setItem("tsol_voucher_gtm_prevent_event", 'false');
    };

    // Wrap sendPageViewEvent with debounce
    const debouncedSendPageViewEvent = debounce(sendPageViewEvent, 1000); // 1000ms = 1 second delay

    // Call once on component mount to ensure a pageview is sent
    debouncedSendPageViewEvent();

    // Listen for history changes with the debounced function
    const unlisten = history.listen(() => {
      debouncedSendPageViewEvent();
    });

    // Cleanup listener on component unmount
    return () => unlisten();
  }, [history]);

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <BlockAccess />
      <Switch>
        {paths &&
          Object.values(paths).map((route) => (
            <Route key={route} exact path={route.path}>
              <route.Page title={route.title}>
                <route.Component />
              </route.Page>
            </Route>
          ))}
        <Route component={() => <PageNotFound />} />
      </Switch>
    </React.Suspense>
  );
}