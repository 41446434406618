import styled from "styled-components";
import { Button as MaterialButton } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

const Button = withTheme(styled(MaterialButton)`
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: inherit;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  text-transform: inherit;
  border-radius: 0px;
  display: ${({ display }) => (display === "block" ? "flex-block" : "flex")};

  &:disabled {
    cursor: not-allowed;
  }

  &:active {
    transform: translateY(2px);
  }

  * {
    font-family: inherit;
    pointer-events: all;
  }
`);

export default Button;
