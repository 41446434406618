import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import usePrismic from "hooks/usePrismic.hook";

const useHeaderStyles = makeStyles((theme) => ({
  appHeader: {
    background: theme.colours.header.background,
    color: theme.colours.header.text,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "26px 20px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "47px 30px 30px",
      justifyContent: "flex-start",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
}));
const HeaderLogo = ({ link }) => {
  const classes = useHeaderStyles();

  let LinkElement;
  let linkProps = {};

  if (!link) {
    LinkElement = Link;
    linkProps.to = "/";
  } else if (link.url) {
    LinkElement = "a";
    linkProps.href = link.url;
    if (link.target) {
      linkProps.target = link.target;
    }
  }

  return (
    <div className={classes.logoHolder}>
      {LinkElement && (
        <LinkElement {...linkProps}>
          <ReactSVG
            className={classes.logo}
            src="/assets/logo-black.svg"
            wrapper="span"
          />
        </LinkElement>
      )}
    </div>
  );
};

export default function Header() {
  const classes = useHeaderStyles();

  const [pageContent, setPageContent] = useState();
  const { fetchPrismicData, pageData } = usePrismic();

  useEffect(() => {
    fetchPrismicData("vouchers_portal_header");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageData && pageData.data) {
      setPageContent(pageData.data);
    }
  }, [pageData]);

  return (
    <Grid container component="header" className={classes.appHeader}>
      <Grid item xs={12} className={classes.logoWrapper}>
        <HeaderLogo link={pageContent?.header_logo_link} />
      </Grid>
    </Grid>
  );
}
