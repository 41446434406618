import { handleActions } from "redux-actions";
import produce from "immer";
import authenticationActions from "redux/authentication/authentication.actions";

const INITIAL_STATE = {
  working: false,
  isAuthenticated: false,
  email: "",
  userDetails: {},
};

const reducer = handleActions(
  {
    [authenticationActions.loginUser.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [authenticationActions.loginUser.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.isAuthenticated = true;
        draft.email = action.payload.email;
      }),
    [authenticationActions.loginUser.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
      }),
    [authenticationActions.registerUser.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [authenticationActions.loginUserFromCookie.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.isAuthenticated = true;
        draft.email = action.payload.email;
      }),
    [authenticationActions.loginUserFromCookie.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
      }),
    [authenticationActions.loginUserFromCookie.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [authenticationActions.registerUser.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.isAuthenticated = true;
        draft.email = action.payload.email;
      }),
    [authenticationActions.registerUser.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
      }),
    [authenticationActions.getUserDetails.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.userDetails = action.payload;
      }),
  },
  INITIAL_STATE
);

export default reducer;
