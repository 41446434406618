import React from "react";
import Heading from "components/molecules/Header.molecule";
import Footer from "components/molecules/Footer.molecule";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import { useCookie } from "hooks/useCookie.hook";

const useVoucherCodePageStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colours.white,
  },
  container: {
    fontFamily: "Scala",
    margin: "0 0 110px",
    backgroundColor: theme.colours.white,
  },
}));

export default function VoucherCodePage({ children }) {
  const classes = useVoucherCodePageStyles();
  const [cookies] = useCookies(["tsol_oauth"]);

  useCookie(cookies);

  return (
    <main className={classes.root}>
      <Heading />
      <section className={classes.container}>{children}</section>
      <Footer />
    </main>
  );
}
