const BASE = "/assets/";

export const images = {
  logo: "logo-white.svg",
  app: {
    ios: "app/ios.svg",
    android: "app/android.svg",
  },
  icons: {
    rightArrow: "icons/arrow-right.svg",
    tick: "icons/tick.svg",
    hiddeneye: "icons/hiddeneye.svg",
  },
  headerBanner: {
    background: "heading/background-coursetile-lightblue.svg",
    phone1: "heading/phone1.png",
    phone2: "heading/phone2.png",
    phone3: "heading/phone3.png",
    backupDesktop: "heading/hero-picture-desktop-backup.jpg",
    backupMobile: "heading/hero-picture-mobile-backup.jpg",
  },
};

export default class ImagePath {
  static returnImage(path) {
    return BASE + path;
  }
}
