import ky from "ky";

const API_ROOT = process.env.REACT_APP_HYD_API;
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_AUTH_CLIENT_SECRET;

const api = ky.create({
  prefixUrl: API_ROOT,
  timeout: 60000,
});

const getAccessToken = () => localStorage.getItem("accessToken");

const authorizedHeader = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
});

// Update marketing preferences on register
const updateMarketingPreferences = (payload) => {
  if (payload.action === "registration") {
    api
      .patch("users", {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
        json: { marketing: true },
      })
      .json();
  }
};

// API Services:
export default class BaseApi {
  static async getPaymentMethods(channel) {
    const response = await api
      .get(`vouchers/payments/methods?channel=${channel}`)
      .json();
      
  // Retrieve the user's email address from local storage.
  const userEmail = localStorage.getItem("tsol_voucher_user_email");

  // Determine the domain of the user's email.
  const domain = userEmail.split('@')[1];  // Split the email string to get the domain part.

  // Filter the payment methods based on the user's email domain.
  const paymentMethods = response.paymentMethods.filter(({ type }) => {
    if (domain === "hyd.agency" || domain === "theschooloflife.com") {
      // If the domain is 'hyd.agency', include these payment methods.
      return ["applepay", "googlepay", "paypal", "scheme"].includes(type);
    } else {
      // For all other domains, include these payment methods.
      return ["applepay", "scheme"].includes(type);
    }
  });

  // Return the filtered payment methods.
  return { paymentMethods };
  }

  static async testVoucherCode({ voucherId }) {
    if (!voucherId) throw new Error("No voucher code provided");
    return api.get(`vouchers/${voucherId}`).json();
  }

  static async getRedeem(code) {
    await api
      .post("vouchers/redeem", {
        headers: authorizedHeader(),
        json: { code },
      })
      .json();
    return true;
  }

  static async validateMerchant(validationUrl) {
    return api
      .post("vouchers/payments/apple-pay-session", {
        headers: authorizedHeader(),
        json: { validationUrl },
      })
      .json();
  }

  static async loginUser(payload) {
    Object.assign(payload, {
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      grantType: "password",
      username: payload.email,
    });
    const response = await api.post("oauth2/token", { json: payload }).json();
    response.email = payload.email;
    return response;
  }

  static async loginUserGoogle(payload) {
    Object.assign(payload, {
      clientId: CLIENT_ID,
      grantType: "google",
      token: payload.access_token,
    });
    const response = api.post("users/connect/google", { 
      json: payload, 
      // headers: {
      //   'X-APP-PLATFORM': 'VP'
      // }
    }).json();

    response.then(updateMarketingPreferences);

    return response;
  }

  /**
   * Log user in
   *
   * This comment pertains to the handling of missing first and last names in user accounts.
   *
   * Context:
   * - When the Apple account has been previously used for TSoL registration.
   *
   * Cause:
   * - Apple Sign-In does not return first and last name fields on subsequent sign-ins.
   * - These details are only provided during the initial sign-in with Apple.
   *
   * Relevant Documentation:
   * - Apple Sign-In REST API: https://developer.apple.com/documentation/signinwithapplerestapi/authenticating_users_with_sign_in_with_apple
   * - Apple Sign-In JS: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js
   * - Developer Forum Thread: https://developer.apple.com/forums/thread/121496
   *
   * @todo Maybe change the signup flow in BE and/or not allow account creation on BE if first and last name are missing?
   */

  static async loginUserApple(payload) {
    Object.assign(payload, {
      clientId: CLIENT_ID,
      grantType: "apple",
      token: payload.authorization.id_token,
      firstName: payload?.user?.name?.firstName,
      lastName: payload?.user?.name?.lastName,
      email: payload?.user?.email,
    });
    const response = api.post("users/connect/apple", { 
      json: payload, 
      // headers: {
      //   'X-APP-PLATFORM': 'VP'
      // }
    }).json();

    response.then(updateMarketingPreferences);

    return response;
  }
  static async getRefreshToken(payload) {
    return api.post("oauth2/token", { json: payload }).json();
  }

  static async redeemVoucher(payload) {
    return api
      .post("vouchers/redeem", {
        json: payload,
        headers: authorizedHeader(),
      })
      .json();
  }

  static async registerUser(payload) {
    payload.clientId = CLIENT_ID;
    const response = await api.post("users", { 
      json: payload, 
      // headers: {
      //   'X-APP-PLATFORM': 'VP'
      // }
    }).json();
    response.email = payload.email;
    return response;
  }

  static async getUserDetails() {
    return api.get("users", { headers: authorizedHeader() }).json();
  }

  static async getUCurrencies() {
    return api
      .get("vouchers/payments/currencies", { headers: authorizedHeader() })
      .json();
  }

  static async resetPassword({ email }) {
    await api.post("users/password/reset", { json: { email } }).json();
    return true;
  }

  static async check3DAuthDetails({ reference, redirectResult }) {
    return api
      .get(
        `vouchers/payments/details/${reference}?redirectResult=${redirectResult}`
      )
      .json();
  } 
  static async postDetails(data,reference ) {
    const response = api.post(`vouchers/payments/details/${reference}`, {
      headers: authorizedHeader(),
      json: data,
    }).json();
    
    return response;
    
  }
}
