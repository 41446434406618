import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import clsx from "clsx";
import usePrismic from "hooks/usePrismic.hook";
import { RichText } from "prismic-reactjs";

/**Footer Logo */
const useFooterLogoStyles = makeStyles((theme) => ({
  logoHolder: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginBottom: 30,
      marginTop: 50
    },
  },
  logo: {
    display: "inline-block",
    width: 94,
    height: 70,
    "& svg": {
      maxHeight: 70,
      maxWidth: 94,
    },
  },
}));

const FooterLogo = ({ link }) => {
  const classes = useFooterLogoStyles();

  let LinkElement;
  let linkProps = {};

  if (!link) {
    LinkElement = Link;
    linkProps.to = "/";
  } else if (link.url) {
    LinkElement = "a";
    linkProps.href = link.url;
    if (link.target) {
      linkProps.target = link.target;
    }
  }

  return (
    <div className={classes.logoHolder}>
      {LinkElement && (
        <LinkElement {...linkProps}>
          <ReactSVG
            className={classes.logo}
            src="/assets/logo-white.svg"
            wrapper="span"
          />
        </LinkElement>
      )}
    </div>
  );
};

/** Footer */
const useFooterStyles = makeStyles((theme) => ({
  footerWrapper: {
    background: theme.colours.nearlyBlack,
    color: theme.colours.white,
    padding: "min(57px, 4%) min(130px, 9%)",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "21.5px 16px 20px 16px",
    },
  },
  footerCenter: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  footerMenu: {
    display: "flex",
    listStyle: "none",
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-end",
    },
    "& li": {
      [theme.breakpoints.down("sm")]: {
        lineHeight: 1.1,
      },
    },
  },
  footerSticky: {
    bottom: 0,
    zIndex: -1,
    position: "absolute",
  },
  separator: {
    padding: "0 min(0.4vw, 10px)",
    [theme.breakpoints.down("md")]: {
      padding: "0 min(0.25vw, 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  info: {
    fontFamily: "OratorStd",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.colours.white,
    textTransform: "lowercase",
    "& p": {
      margin: 0,
    },
  },
  link: {
    fontFamily: "BebasNeue",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    color: theme.colours.white,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      lineHeight: 1.15,
      textAlign: "right",
    },
    "& p": {
      margin: 0,
    },
  },
  socialIconsWrapper: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  socialLinks: {
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  socialIcons: {
    height: 20,
    display: "inline-block",
    "& svg": {
      maxHeight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      height: 24,
      "& svg": {
        maxHeight: 24,
      },
    },
  },
}));

export default function Footer(props) {
  const [pageContent, setPageContent] = useState();

  const classes = useFooterStyles();

  const { fetchPrismicData, pageData } = usePrismic();

  useEffect(() => {
    fetchPrismicData("vouchers_portal_footer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageData && pageData.data) {
      setPageContent(pageData.data);
    }
  }, [pageData]);

  return (
    <Grid
      container
      className={clsx(classes.footerWrapper, {
        [classes.footerSticky]: props.sticky,
      })}
    >
      <Grid item xs={6} md={3} className={classes.footerLogo}>
        <FooterLogo link={pageContent?.footer_logo_link} />
      </Grid>
      <Grid item xs={6} md={6} className={classes.footerCenter}>
        <div className={classes.info}>
          {pageContent && RichText.render(pageContent?.footer_links_title)}
        </div>
        <ul className={classes.footerMenu}>
          {pageContent &&
            pageContent?.footer_links?.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link_address[0].text}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  {RichText.render(item?.link)}
                  {index !== pageContent?.footer_links.length - 1 && (
                    <span className={classes.separator}>|</span>
                  )}
                </a>
              </li>
            ))}
        </ul>
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={classes.socialIconsWrapper}>
          {pageContent &&
            pageContent?.social_icons?.map((item, index) => (
              <a
                className={classes.socialLinks}
                href={item?.social_link?.url}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG
                  className={classes.socialIcons}
                  src={item?.social_icon.url}
                  wrapper="span"
                />
              </a>
            ))}
        </div>
      </Grid>
    </Grid>
  );
}
