import React from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";

const IS_ONLINE = "online";
const IS_OFFLINE = "offline";

const Banner = withTheme(styled.div`
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.background.grey};
  color: ${({ theme }) => theme.palette.text.grey};
  z-index: 6;
`);

export default function NetworkGate({ children }) {
  const [isOnline, setIsOnline] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener(IS_ONLINE, () => {
      setIsOnline(true);
    });
    window.addEventListener(IS_OFFLINE, () => {
      setIsOnline(false);
    });
  }, [setIsOnline]);

  if (isOnline) {
    return children;
  } else {
    return (
      <div>
        <Banner>OFFLINE MESSAGE</Banner>
        <div>{children}</div>
      </div>
    );
  }
}
