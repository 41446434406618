import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "services/BaseApi.service";

let authenticationActions = {};

authenticationActions.loginUser = createAsyncThunk(
  "LOGIN_USER",
  async (formObject) => {
    return await BaseApi.loginUser(formObject);
  }
);

authenticationActions.loginUserFromCookie = createAsyncThunk(
  "LOGIN_USER_FROM_COOKIE",
  async (payload) => {
    return await BaseApi.getRefreshToken(payload);
  }
);

authenticationActions.loginUserFromGoogle = createAsyncThunk(
  "LOGIN_USER_FROM_GOOGLE",
  async (payload) => {
    return await BaseApi.loginUserGoogle(payload);
  }
);

authenticationActions.loginUserFromApple = createAsyncThunk(
  "LOGIN_USER_FROM_APPLE",
  async (payload) => {
    return await BaseApi.loginUserApple(payload);
  }
);

// rejectWithValue : https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
authenticationActions.registerUser = createAsyncThunk(
  "REGISTER_USER",
  async (formObject, { rejectWithValue }) => {
    try {
      return await BaseApi.registerUser(formObject);
    } catch (err) {
      // get actual data from the API (have to await since it's a ReadStream)
      const data = await err.response.json();
      const includesDetailMessage = data.details?.[0]?.message;
      let errorResponse = data.message;

      if (includesDetailMessage) {
        const errorMsg = data.details.reduce((acc, item) => {
          acc += `${item.message}. `;
          return acc;
        }, "");

        errorResponse = errorMsg;
      }
      return rejectWithValue(errorResponse);
    }
  }
);

authenticationActions.resetPassword = createAsyncThunk(
  "RESET_PASSWORD",
  async (formObject) => {
    return await BaseApi.resetPassword(formObject);
  }
);

authenticationActions.getUserDetails = createAsyncThunk(
  "GET_USER_DETAILS",
  async () => {
    return await BaseApi.getUserDetails();
  }
);

authenticationActions.redeemVoucherCode = createAsyncThunk(
  "REDEEM_VOUCHER_CODE",
  async (payload) => {
    return await BaseApi.redeemVoucher(payload);
  }
);

export default authenticationActions;
