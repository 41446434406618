import React from "react";
import Button from "components/primitives/Button.primitive";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useUnderlineButtonStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "underline",
    fontFamily: "BebasNeue",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "right",
    color: theme.colours.veryDarkGray,

    "&.accent": {
      backgroundColor: theme.colours.vividYellow,
      "&:hover": {
        backgroundColor: theme.colours.vividYellow,
      },
    },

    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },

    "& pre": {
      margin: 0,
    },
    "& p": {
      margin: 0,
    },
  },
}));

export default function UnderlineButton({ children, className, ...props }) {
  const classes = useUnderlineButtonStyles();
  return (
    <Button {...props} className={clsx(classes.root, className)}>
      {children}
    </Button>
  );
}
