import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useHighlightedHeaderStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "BebasNeue",
    fontSize: 48,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.04,
    letterSpacing: "normal",
    color: theme.colours.black,
    [theme.breakpoints.down("xs")]: {
      fontSize: 36,
      lineHeight: "normal",
      maxWidth: "380px",
    },
    "& span": {
      backgroundColor: theme.colours.vividYellow,
      padding: "0 5px",
    },
    "& strong": {
      backgroundColor: theme.colours.vividYellow,
      padding: "0 5px",
    },
    "& p": {
      marginBottom: "20px",
    },
  },
}));

export default function HighlightedHeader({ children, className }) {
  const classes = useHighlightedHeaderStyles();
  return <div className={clsx(className, classes.root)}>{children}</div>;
}
