import ky from "ky";
const API_ROOT = process.env.REACT_APP_IP_API;
const api = ky.create({
  prefixUrl: API_ROOT,
});

// API Services:
export default class IpApi {
  static async fetchUserLocation() {
    const API_KEY = process.env.REACT_APP_IP_API_KEY;
    const data = await api.get(`json/?key=${API_KEY}`).json();
    return data;
  }
}
