import { createAsyncThunk } from "@reduxjs/toolkit";
import PrismicService from "services/PrismicService";

let prismicActions = {};

prismicActions.fetchPageBySlug = createAsyncThunk(
  "FETCH_PRISMIC_PAGE_BY_SLUG",
  async (pageSlug) => {
    return await PrismicService.getPageBySlug(pageSlug);
  }
);

export default prismicActions;
