import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import authenticationActions from "redux/authentication/authentication.actions";

export const useCookie = () => {
  const [cookies] = useCookies(["tsol_oauth"]);
  const dispatch = useDispatch();

  const getTokenFromCookie = async () => {
    let accessToken, refreshToken;

    if (
      cookies?.tsol_oauth &&
      cookies.tsol_oauth?.accessToken &&
      cookies.tsol_oauth?.refreshToken &&
      cookies.tsol_oauth?.expiryDate * 1000 > Date.now() && // convert to ms
      !localStorage.getItem("accessToken") &&
      !localStorage.getItem("refreshToken")
    ) {
      // get access token from cookie
      accessToken = cookies.tsol_oauth.accessToken;
      // get refresh token from cookie
      refreshToken = cookies.tsol_oauth.refreshToken;
    }

    // if we have both accessToken and refreshToken we make the API call to get new tokens
    if (refreshToken && accessToken) {
      // construct payload
      const payload = {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      dispatch(authenticationActions.loginUserFromCookie(payload))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const { accessToken, refreshToken } = originalPromiseResult;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("tsol_voucher_user_isAuthenticated", true);

          dispatch(authenticationActions.getUserDetails())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
              const { email } = originalPromiseResult;
              localStorage.setItem("tsol_voucher_user_email", email);
            });
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log("There was an error during login");
        });
    }
  };

  // Logout when cookie expires
  useEffect(() => {
    if (!cookies.tsol_oauth) {
      // toast.error(<PleaseLogin />);
      localStorage.removeItem("tsol_voucher_user_isAuthenticated");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tsol_voucher_user_email");
    }
  }, [cookies]);

  // Reuse the token refresh logic
  useEffect(() => {
    getTokenFromCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
