import React from "react";
import Heading from "components/molecules/Header.molecule";
import Footer from "components/molecules/Footer.molecule";
import { makeStyles } from "@material-ui/core/styles";
import HighlightedHeader from "components/atoms/HighlightedHeader.atom";
import HeaderBanner from "components/molecules/HeaderBanner.molecule";
import Typography from "@material-ui/core/Typography";
import PageWidth from "components/primitives/PageWidth.primitive";

const useLoadingPageStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Scala",
    margin: "0 0 110px",
  },
  pageHeight: {
    minHeight: "60vh",
  },
  content: {
    fontFamily: "Scala",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    color: theme.colours.black,
    margin: "15px 0 25px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      lineHeight: 1.56,
    },
  },
}));
const LoadingPage = () => {
  const classes = useLoadingPageStyles();

  return (
    <main>
      <Heading />
      <div className={classes.container}>
        <HeaderBanner />
        <PageWidth>
          <div className={classes.pageHeight}>
            <HighlightedHeader>Loading</HighlightedHeader>
            <Typography variant="body1" className={classes.content}>
              Your page is loading
            </Typography>{" "}
          </div>
        </PageWidth>
      </div>
      <Footer />
    </main>
  );
};

export default LoadingPage;
