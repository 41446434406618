import { useState } from "react";
import PrismicService from "services/PrismicService";
import { toast } from "react-toastify";

export default function usePrismic() {
  const [pageData, setPageData] = useState();
  const [working, setWorking] = useState(true);

  const fetchPrismicData = async (pageSlug) => {
    setWorking(true);
    try {
      const response = await PrismicService.getPageBySlug(pageSlug);
      setWorking(false);
      setPageData(response);
    } catch (e) {
      setPageData();
      setWorking(false);
      toast.error(`There was getting data for ${pageSlug}`);
    }
  };

  return { fetchPrismicData, pageData, working };
}
