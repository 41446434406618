import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import IpApi from "services/IpApi.service";

const BlockAccess = () => {
  const [countryName, setCountryName] = useState();
  const blockedCountries = ["Brazil", "China", "India", "Russia", "South Africa"];

  useEffect(() => {
    if(!countryName) {
      async function getUserCountry() {
        const data = await IpApi.fetchUserLocation();
        setCountryName(data.country);
        return data;
      }

      getUserCountry();
    }
  },[countryName]);

  return (
    <>
      {countryName && blockedCountries.includes(countryName) && <Redirect to="/tax-restriction" />}
    </>
  )
};

export default BlockAccess;
