import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import BaseApi from "services/BaseApi.service";
import IpApi from "services/IpApi.service";

let currencyActions = {};

currencyActions.fetchCurrencies = createAsyncThunk(
  "FETCH_CURRENCIES",
  async () => {
    return await BaseApi.getUCurrencies();
  }
);

currencyActions.getUserLocalCurrency = createAsyncThunk(
  "GET_USER_LOCAL_CURRENCY",
  async () => {
    return await IpApi.fetchUserLocation();
  }
);

// Define the setCurrency action
currencyActions.setCurrency = createAction("SET_CURRENCY");

export const { fetchCurrencies, getUserLocalCurrency, setCurrency } = currencyActions;

export default currencyActions;
