import { ThemeProvider as StyledProvider } from 'styled-components';
import { ThemeProvider as MaterialProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

export default function MasterTheme({ theme, children }) {
  return (
    <MaterialProvider theme={theme}>
      <StyledProvider theme={theme}>
        <CssBaseline />
        {children}
      </StyledProvider>
    </MaterialProvider>
  )
}