import ky from "ky";

const API_ROOT = process.env.REACT_APP_HYD_API;
const api = ky.create({ prefixUrl: API_ROOT });

// API Services:
export default class PrismicService {
  static async getPageBySlug(pageSlug) {
    return await api.get(`vouchers/contents/${pageSlug}`).json();
  }
}
