import { handleActions } from "redux-actions";
import produce from "immer";
import currencyActions from "./currency.actions";
import { getParamByISO } from "iso-country-currency";

const INITIAL_STATE = {
  working: false,
  currencies: [],
  currencyErrors: null,
  localCurrency: null,
};

const reducer = handleActions(
  {
    [currencyActions.fetchCurrencies.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [currencyActions.fetchCurrencies.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.currencies = action.payload;
      }),
    [currencyActions.fetchCurrencies.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.currencyErrors = action.payload;
      }),

    [currencyActions.getUserLocalCurrency.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [currencyActions.getUserLocalCurrency.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.countryCode = action.payload.countryCode;
        draft.localCurrency = getParamByISO(
          action.payload.countryCode,
          "currency"
        );
      }),
    [currencyActions.getUserLocalCurrency.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.localCurrency = action.payload;
      }),
      
    // Handle the setCurrency action
    [currencyActions.setCurrency]: (state, action) =>
      produce(state, (draft) => {
        draft.localCurrency = action.payload;
      }),
  },
  INITIAL_STATE
);

export default reducer;
