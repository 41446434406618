import React, { useEffect } from "react";
import BaseApi from "services/BaseApi.service";
import { toast } from "react-toastify";
import PleaseLogin from "components/molecules/PleaseLogin.molecule";
import { useCookies } from "react-cookie";

export const useToken = () => {
  const [cookies] = useCookies(["tsol_oauth"]);

  // call token API.
  const getUpdateRefreshToken = async () => {
    // get access token from localStorage.
    const accessToken = localStorage.getItem("accessToken");
    // get refresh token from localStorage.
    const refreshToken = localStorage.getItem("refreshToken");

    // if we have both accessToken and refreshToken we make the API call to get new tokens
    if (refreshToken && accessToken) {
      if (!cookies.tsol_oauth) {
        // show links to login/sign up
        toast.error(<PleaseLogin />);
        // since API failed
        localStorage.removeItem("tsol_voucher_user_isAuthenticated");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tsol_voucher_user_email");
        return;
      }
      // construct payload.
      const payload = {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      // actual API call
      try {
        const response = await BaseApi.getRefreshToken(payload);
        // when we get response set new refresh/access token and isAuthenticated to true
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("tsol_voucher_user_isAuthenticated", true);
      } catch (e) {
        // show links to login/sign up
        toast.error(<PleaseLogin />);
        // since API failed
        localStorage.removeItem("tsol_voucher_user_isAuthenticated");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tsol_voucher_user_email");
      }
    }
  };

  // make call every 9 mins as refresh token is valid for 10mins
  useEffect(() => {
    const intervalId = setInterval(getUpdateRefreshToken, 540000);

    return () => clearInterval(intervalId); // Unmount function, clears interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
