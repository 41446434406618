import { handleActions } from "redux-actions";
import produce from "immer";
import voucherFlowActions from "redux/voucher/voucherFlow.actions";

const INITIAL_STATE = {
  working: false,
  voucherId: null,
  voucherCodeDetails: {
    currency: null,
    discountAmount: null,
    discountLimit: null,
    discountType: null,
    initialPrice: null,
    valid: null,
    finalPrice: 15000,
  },
  voucherCodeErrors: null,
};

const reducer = handleActions(
  {
    [voucherFlowActions.setVoucherCode]: (state, action) =>
      produce(state, (draft) => {
        draft.voucherId = action.payload;
      }),
    [voucherFlowActions.testVoucherCode.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),
    [voucherFlowActions.testVoucherCode.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        const {
          currency,
          discountAmount,
          discountLimit,
          discountType,
          initialPrice,
          valid,
          finalPrice,
        } = action.payload;
        draft.voucherCodeDetails = {
          currency,
          discountAmount,
          discountLimit,
          discountType,
          initialPrice,
          valid,
          finalPrice,
        };
      }),
    [voucherFlowActions.testVoucherCode.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.voucherCodeErrors = action.payload;
      }),
  },
  INITIAL_STATE
);

export default reducer;
