import { handleActions } from "redux-actions";
import produce from "immer";
import prismicActions from "redux/prismic/prismic.actions";

const INITIAL_STATE = {
  working: false,
  pageData: {},
};

const reducer = handleActions(
  {
    [prismicActions.fetchPageBySlug.pending]: (state, action) =>
      produce(state, (draft) => {
        draft.working = true;
      }),

    [prismicActions.fetchPageBySlug.fulfilled]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
        draft.pageData = action.payload;
      }),
    [prismicActions.fetchPageBySlug.rejected]: (state, action) =>
      produce(state, (draft) => {
        draft.working = false;
      }),
  },
  INITIAL_STATE
);

export default reducer;
