import React from "react";
import RouteFactory from "classes/RouteFactory.class";
import VoucherCodePage from "../components/pages/VoucherCode.page";

const LandingPage = React.lazy(() =>
  import("../components/organisms/LandingPage.organism")
);
const CreateAccount = React.lazy(() =>
  import("../components/organisms/CreateAccount.organism")
);
const LoginToAccount = React.lazy(() =>
  import("../components/organisms/LoginToAccount.organism")
);
const RecoverPassword = React.lazy(() =>
  import("../components/organisms/RecoverPassword.organism")
);
const EnterVoucherCode = React.lazy(() =>
  import("../components/organisms/EnterVoucherCode.organism")
);
const OrderSummary = React.lazy(() =>
  import("../components/organisms/OrderSummary.organism")
);
const ConfirmationPage = React.lazy(() =>
  import("../components/organisms/ConfirmationPage.organism")
);
const RedirectingPage = React.lazy(() =>
  import("../components/organisms/RedirectingPage.organism")
);
const TaxRestrictionPage = React.lazy(() =>
  import("../components/organisms/TaxRestriction.organism")
);

const paths = {
  landingPage: new RouteFactory({
    path: "/",
    component: LandingPage,
    title: "THE LANDING PAGE",
    page: VoucherCodePage,
  }),
  createAccount: new RouteFactory({
    path: "/create-account",
    component: CreateAccount,
    title: "THE CA PAGE",
    page: VoucherCodePage,
  }),
  loginToAccount: new RouteFactory({
    path: "/login",
    component: LoginToAccount,
    title: "THE LOGIN PAGE",
    page: VoucherCodePage,
  }),
  recoverPassword: new RouteFactory({
    path: "/recover-password",
    component: RecoverPassword,
    title: "THE RECOVER PASSWORD PAGE",
    page: VoucherCodePage,
  }),
  enterVoucherCode: new RouteFactory({
    path: "/use-voucher",
    component: EnterVoucherCode,
    title: "THE ENTER VOUCHER PAGE",
    page: VoucherCodePage,
  }),
  orderSummary: new RouteFactory({
    path: "/order-summary",
    component: OrderSummary,
    title: "THE ORDER SUMMARY PAGE",
    page: VoucherCodePage,
  }),
  confirmation: new RouteFactory({
    path: "/confirmation",
    component: ConfirmationPage,
    title: "THE CONFIRMATION PAGE",
    page: VoucherCodePage,
  }),
  redirecting: new RouteFactory({
    path: "/redirecting",
    component: RedirectingPage,
    title: "THE REDIRECT PAGE",
    page: VoucherCodePage,
  }),
  taxRestriction: new RouteFactory({
    path: "/tax-restriction",
    component: TaxRestrictionPage,
    title: "Tax Restriction",
    page: TaxRestrictionPage,
  }),
};

export default paths;
